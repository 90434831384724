import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import ImageResize from "quill-image-resize-module-react";

// HELPERS
import { buildModules } from "./helpers";
import { getSource } from "app/utils/content";

// COMPONENTS
import ModalLink from "app/components/molecules/modals/modalLink";
import ModalMedia from "app/components/molecules/modals/modalMedia";

// STYLES
import "react-quill/dist/quill.snow.css";
import validator from "validator";

// Custom Image Blot to handle "alt" attribute
const BlockEmbed = Quill.import("blots/block/embed");

class CustomImage extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute("src", value.url);
    node.setAttribute("alt", value.alt);
    node.setAttribute("title", value.title);
    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute("src"),
      alt: node.getAttribute("alt"),
      title: node.getAttribute("title"),
    };
  }
}

CustomImage.blotName = "customImage";
CustomImage.tagName = "img";
Quill.register(CustomImage);
Quill.register("modules/imageResize", ImageResize);

export default function Editor(props) {
  const { idForm, disabled, title, description, value, modules, formats, forecolors, backcolors, isRequired, error, errorMessage, onChange } = props;

  // VARS
  const styles = {
    title: "block text-sm font-medium mb-1",
    description: "block text-xs font-light text-slate-400 -mt-1.5 mb-1",
  };

  // STATE
  const [modal, setModal] = useState(null);
  const [ready, setReady] = useState(false);
  const [linkModal, setLinkModal] = useState(null);

  if (error) styles.title += " text-red-600";
  else styles.title += " text-slate-700";

  const quillRef = useRef(null);

  const imageHandler = async () => {
    setModal({
      isOpen: true,
      withAlt: true,
      withTitle: true,
      onClose: () => setModal(null),
      onSubmit: (item) => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        if (range) quill.insertEmbed(range.index, "customImage", { url: getSource(item.path), title: item.title, alt: item.alt });

        setModal(null);
      },
    });
  };

  const linkHandler = () => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    const text = range ? quill.getText(range.index, range.length) : "";

    console.log(text);

    setLinkModal({
      isOpen: true,
      text: { url: text },
      onClose: () => setLinkModal(null),
      onSubmit: (data) => {
        if (range) {
          const isEmail = validator.isEmail(data.url);
          const url = isEmail ? `mailto:${data.url}` : data.url;
          quill.deleteText(range.index, range.length);
          quill.insertText(range.index, text, "link", url);
        }
        setLinkModal(null);
      },
    });
  };

  const modues = useMemo(
    () => ({
      toolbar: {
        container: [...buildModules(modules, formats, forecolors, backcolors)],
        handlers: {
          image: imageHandler,
          link: linkHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idForm]
  );

  // Função para converter HTML para Delta
  useEffect(() => {
    if (quillRef.current && value && !ready) {
      const editor = quillRef.current.getEditor();
      const delta = editor.clipboard.convert(value); // Converte o HTML para Delta
      // console.log({ value, delta });
      editor.setContents(delta); // Define o conteúdo convertido
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="block mb-3">
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className={`input-editor${error ? " input-editor-error" : ""}`}>
        <ReactQuill readOnly={disabled} id={idForm} key={idForm} theme="snow" ref={quillRef} defaultValue={value} onChange={onChange} modules={modues} className="h-80 pb-10" />
      </div>

      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}

      <ModalMedia {...modal} />
      <ModalLink {...linkModal} />
    </div>
  );
}
